import * as React from "react";
import {
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
	Icon,
	IconUnstar,
	IconStar,
	Button,
	cn,
} from "@siteimprove/fancylib";
import * as scss from "./starred.scss";

export type StarredProps = {
	/** If true, the element selected is starred */
	isStarred: boolean;
	/** Executes once starred button has changed */
	onChange: (starred: boolean) => void;
	/** Describe what happens if the button is clicked */
	"aria-label"?: string;
	/** Can the button be clicked */
	disabled?: boolean;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function Starred(props: StarredProps): JSX.Element {
	const {
		isStarred,
		onChange,
		"aria-label": ariaLabel,
		className,
		style,
		tabIndex,
		disabled,
		onKeyDown,
		onMouseDown,
		onFocus,
		onBlur,
		onMouseEnter,
		onMouseLeave,
	} = props;

	return (
		<Button
			data-component="starred"
			data-observe-key={props["data-observe-key"]}
			aria-label={ariaLabel}
			aria-pressed={isStarred}
			onClick={(e) => {
				e.stopPropagation();
				onChange(!isStarred);
			}}
			size="large"
			variant="borderless"
			tabIndex={tabIndex}
			className={cn(scss.starredBtn, isStarred && scss.starred, className)}
			style={style}
			disabled={disabled}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Icon>{isStarred ? <IconStar /> : <IconUnstar />}</Icon>
		</Button>
	);
}
