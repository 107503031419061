import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { H4, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Starred } from "./starred";

export const Meta: DocPageMeta = {
	category: "Actions and controls", // Which category does the component belong to?,
	title: "Starred", // Should the name in the styleguide menu be different from the component name?,
	notepad: "https://hackmd.io/Jf9JCA42SnC8IknN4vq30w", //Go to https://hackmd.io/ , create an account, and create a new note. Provide the url to the note here.
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Starred"
			subTitle="A Starred button allows users to easily like, save, or prioritize an item. It often pins the item to the top of a list or collection for quick access."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Starred} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Use default starred variant for users to toggle the starred state of an item, and enable
					starring on most lists to allow customisation.
				</Paragraph>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Icon only button</b>: the primary interactive element.
						</>,
						<>
							<b>Star Icon</b>: The primary visual element.
						</>,
						<>
							<b>Tooltip</b> (Recommended): A brief text label appearing on hover or focus (e.g.,
							"Marked as favorite").
						</>,
					]}
				/>
				<Paragraph>
					<b>Interaction</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Default (Unstarred)</b>: An outlined star icon is used to show the item is not
							starred.
						</>,
						<>
							<b>Hover</b>: A blue round square border and shadow displayed to indicate that is
							clickable.
						</>,
						<>
							<b> Starred</b>: Typically, a filled star icon is used to indicate the item is
							starred.
						</>,
						<>
							<b> Disabled</b>: The icon appears in a visually muted state (e.g., grayed out) with
							no interaction.
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Paragraph>
					Disable the starred button when the user cannot star an item (e.g., due to permissions) or
					in read-only views.
				</Paragraph>
				<Example fn={DisabledStateExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Starred}
					initialProps={({ setState }) => ({
						isStarred: false,
						onChange: (item) => setState({ isStarred: item }),
						"aria-label": "Pin/unpin whatever this item is",
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Starred</Code> when
					</Paragraph>
					<Ul
						items={[
							<>Users need to quickly mark or bookmark items of interest.</>,
							<>The list or collection of items can benefit from personalization.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Starred</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables and lists/List table">List Table</GatsbyLink>
								: Usually positioned at the leading edge of the item. (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FPolicy%2F1352515706%2FContentPolicies%2FIndex%2F1%2FChangedDate%2FDesc%3FpinnedOnly%3Dfalse%26pageSize%3D50%26policies-tab%3D0%26lang%3Den-US">
									{" "}
									My policies
								</a>
								)
							</>,
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Table">Data table</GatsbyLink> :
								Usually positioned at the leading edge of the item. (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FDci%2F446001%2FOverview%2FSitesProgress%3FLoadPersonalFilters%3DTrue%26lang%3Den-US">
									{" "}
									Sites progress
								</a>
								)
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your{" "}
								<Code>Starred</Code> to existing components for visual consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								A more specific action is appropriate (e.g., "Prioritise issue in
								{""}
								<a href="https://my2.siteimprove.com/SEOv2/877948/Insights/Index?lang=en-US">
									{" "}
									SEO Insights
								</a>
								" ).
							</>,
							<>The star icon might be confused with rating functionality. </>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>Use adequate color contrast for all states of the button.</>,
							<>Ensure the touch/click target is large enough.</>,
							<>
								Provide alternative text for screen readers (e.g., "Star item" or "Unstar item").
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>Tooltip/Label Text</Paragraph>
					<Ul
						items={[
							<>
								<b>Starred</b>: "Starred" (or "Favorited")
							</>,
							<>
								<b>Unstarred</b>: "Star" (or "Favorite")
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const [starred, setStarred] = useState(false);
	return (
		<Starred
			isStarred={starred}
			onChange={setStarred}
			aria-label="Pin/unpin whatever this item is"
		/>
	);
};

const DisabledStateExample = () => {
	const [starred, setStarred] = useState(false);
	return (
		<Starred
			isStarred={starred}
			onChange={setStarred}
			aria-label="Pin/unpin whatever this item is"
			disabled
		/>
	);
};
